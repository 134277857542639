import axios from 'axios';

class TextingGroupDataService {
    constructor() {
        this.baseUrl = process.env.VUE_APP_API_BASE;
    }

    // async getGroups() {
    //     try {
    //         const response = await axios.get(`${this.baseUrl}/groups/list`)
    //         return response.data;
    //     } catch (error) {
    //         console.error(error);
    //         throw new Error('TextingGroupDataService - getGroups()');
    //     }
    // }

    // async get(groupId) {
    //     console.log('group id: ', groupId);
    //     try {
    //         const response = await axios.get(`${this.baseUrl}/groups/${groupId}`);
    //         return response.data;
    //     } catch (error) {
    //         console.error(error);
    //         throw new Error('TextingGroupDataService - get()');
    //     }
    // }

    // async save(payload) {
    //     try {
    //         const response = await axios.post(`${this.baseUrl}/groups`, payload);
    //         return response.data;
    //     } catch (error) {
    //         console.error(error);
    //         throw new Error('TextingGroupDataService - save()');
    //     }
    // }

    // async update(payload) {
    //     try {
    //         const response = await axios.put(`${this.baseUrl}/groups`, payload);
    //         return response.data;
    //     } catch (error) {
    //         console.error(error);
    //         throw new Error ('TextingGroupDataService - update()');
    //     }
    // }

    async getGroupsV2() {
        try {
            console.log(this.baseUrl);
            const response = await axios.get(`${this.baseUrl}/groups/v2/list`)
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - getGroups()');
        }
    }

    async getV2(groupId) {
        console.log('group id: ', groupId);
        try {
            const response = await axios.get(`${this.baseUrl}/groups/v2/${groupId}`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - get()');
        }
    }

    async getGroupsForRegistration() {
        try {
            const response = await axios.get(`${this.baseUrl}/groups/v2/registration`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - getGroupsForRegistration()');
        }
    }

    async saveV2(payload) {
        try {
            const response = await axios.post(`${this.baseUrl}/groups`, payload);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - save()');
        }
    }

    async updateV2(payload) {
        try {
            const response = await axios.post(`${this.baseUrl}/groups/update`, payload);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - save()');
        }
    }

    async createTextingGroupWithOwners(payload, ownerUsernames) {
        let updatedOwners = [];
        for (let username of ownerUsernames) {
            updatedOwners.push(`ownerUsernames=${username}`);
        }
        let ownerStr = '';
        if (updatedOwners.length > 0) {
            ownerStr = `?${updatedOwners.join('&')}`;
        }
        try {
            const response = await axios.post(`${this.baseUrl}/groups/createGroupWithOwners${ownerStr}`, payload);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - createTextingGroupWithOwners()');
        }
    }

    async assignOwnersToTextingGroup(textingGroupId, ownerUsernames) {
        let updatedOwners = [];
        for (let username of ownerUsernames) {
            updatedOwners.push(`ownerUsernames=${username}`);
        }
        let ownerStr = '';
        if (updatedOwners.length > 0) {
            ownerStr = `?${updatedOwners.join('&')}`;
        }
        try {
            const response = await axios.post(`${this.baseUrl}/groups/assignOwnersToTextingGroup/${textingGroupId}${ownerStr}`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - assignOwnersToTextingGroup()');
        }
    }

    async getOwnersByUsername(username) {
        try {
            const response = await axios.get(`${this.baseUrl}/groupOwner/username/${username}`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - getOwnersByUsername()');
        }
    }

    async getGroupOwnersV2() {
        try {
            const response = await axios.get(`${this.baseUrl}/groupOwner/list`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - getGroupOwnersV2()');
        }
    }

    async saveAllOwnersV2(payload) {
        try {
            const response = await axios.post(`${this.baseUrl}/groupOwner/saveAll`, payload);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - saveAllOwnersV2()');
        }
    }

    async removeOwners(owners) {
        try {
            const response = await axios.delete(`${this.baseUrl}/groupOwner/deleteAll`, {data: {owners: owners}});
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - removeOwners()');
        }
    }
}

export default TextingGroupDataService;