import axios from "axios";
import LocalStorageService from "@/services/localstorageservice"
import { toastBus } from '@/main'
//import router from "@/router";

const localStorageService = LocalStorageService.getService();

export default {
  install: function (app, params = {}) {

    // updateToken method's Keycloak documentation states that it returns a promise, 
    // but it actually isn't a thenable and thus the await keyword does not think it as a valid Promise.
    function refreshToken(minValidity) {

        return new Promise((resolve, reject) => {
            
                // app.config.globalProperties.$keycloak.updateToken(minValidity).then(function () {
                //     localStorageService.setAuthTokens(app.config.globalProperties.$keycloak);
                //     resolve()
                // }).catch(function () {
                //     console.error('Failed to refresh token' )
                //     localStorageService.clearAuthTokens()
                //     window.location.href = '/timeout'
                //     reject();
                // });

                app.config.globalProperties.$keycloak.updateToken(minValidity).then(function () {
                    localStorageService.setAuthTokens(app.config.globalProperties.$keycloak);
                    resolve();
                }).catch(function () {
                    console.error('Failed to refresh token');
                    localStorageService.clearAuthTokens();
                    window.location.href = '/timeout';
                    reject();
                });
            
        });
    }
        
    // Add a request interceptor
    axios.interceptors.request.use(
        async config => {
            config.headers['Content-Type'] = 'application/json';
            //config.headers['Cache-Control'] = 'no-cache'

            // if (config.payment && config.payment === true) {
            //     config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
            // }

            if (config.ignoreHeader && config.ignoreHeader === true) {
                return config
            }

            if (config.url.includes("/timeout") || config.url.includes("/error")
                || config.url.includes("/unauthorized")) {
                return config
            }

            await refreshToken(70);

            const token = localStorageService.getAccessToken();
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        },
        error => {
            Promise.reject(error)
        });

        // Add a response interceptor
        axios.interceptors.response.use(
            response => {
                if (response.status === 401 || response.status === 403) {
                    window.location.href = '/unauthorized'
                }                  
                return response
            }, 
            error => {
                console.log(error)     
                if (!error.request.responseURL.includes('/openid-connect/token')) {       
                    toastBus.emit('add', { severity: 'error', summary: 'Something went wrong',
                        detail: 'An unexpected error has occurred.', group: 'appError'})
                }
            }
        )
    }

}
